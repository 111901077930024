function initialize(
    my_form,
    validated,
    buton_submit,
    on_start,
    auto_focus,
    name_class,
    url,
    parentClass
) {
    my_form.on("submit", function () {
        if (validated == true) {
            var $form = $(this);
            if ($form.data("submitted") === true) {
                e.preventDefault();
            } else {
                $form.data("submitted", true);
            }
            return true;
        } else {
            buton_submit = false;
            validate(name_class, url, parentClass, buton_submit);
        }
    });

    my_form.find("input[type=submit]").on("click", function (e) {
        e.preventDefault();
        buton_submit = true;
        validate(name_class, url, parentClass, buton_submit);
    });

    my_form
        .find(".form-error")
        .append('<span class="help-block with-errors"></span>');

    my_form.find(":input").each(function () {
        $(this).on("change", function () {
            buton_submit = false;
            validate(name_class, url, parentClass, buton_submit);
        });
    });

    if (on_start == "1") {
        validate(name_class, url, parentClass, buton_submit);
    }

    if (auto_focus) {
        $(":input:enabled:visible:first").on("focus"); //.focus();
    }
}

function validate(name_class, url, parentClass, buton_submit) {
    var data = my_form.serializeArray();
    $(".form-error").show();
    data.push({
        name: "class",
        value: name_class,
    });

    for (var i = 0; i < data.length; i++) {
        item = data[i];
        if (item.name == "_method") {
            data.splice(i, 1);
        }
    }

    $.ajax({
        url: "/validation", //url,
        type: "post",
        data: $.param(data),
        dataType: "json",
        success: function (data) {
            if (data.success) {
                /* alert(data.success); */
                $.each(my_form.serializeArray(), function (i, field) {
                    //alert(field.name);
                    var fieldName = field.name;
                    if (!fieldName.endsWith("]")) {
                        var father = $("#" + field.name).parents(
                            "." + parentClass
                        );
                        //  alert(father);
                        father.removeClass("has-error");
                        father.addClass("has-success");
                        father.find(".help-block").html("");
                    }
                });

                $(".error-summary").removeClass("alert-danger");
                $(".error-summary").html("");
                validated = true;
                if (buton_submit == true) {
                    my_form.submit();
                }
            } else {
                $(".error-summary").show();
                var campos_error = [];
                // console.log('.' + parentClass);
                $.each(data.errors, function (key, data) {
                    // console.log(data[0]);
                    var campo = $("#" + key);
                    var father = campo.parents("." + parentClass);
                    // console.log(father);
                    father.removeClass("has-success");
                    father.addClass("has-error");
                    father.find(".help-block").html(data[0]);
                    campos_error.push(key);
                });

                $(".error-summary").addClass("alert-danger");
                $(".error-summary").html(
                    "Error! Please fill all the required fields"
                );
                $.each(my_form.serializeArray(), function (i, field) {
                    var fieldName = field.name;
                    if (fieldName.endsWith("]")) {
                        var strIndex = fieldName.indexOf("[");
                        fieldName = fieldName.slice(0, strIndex);
                        // console.log('str length ' +fieldName);
                    }
                    if ($.inArray(fieldName, campos_error) === -1) {
                        var father = $("#" + fieldName).parents(
                            "." + parentClass
                        );
                        father.removeClass("has-error");
                        father.addClass("has-success");
                        father.find(".help-block").html("");
                    }
                });

                validated = false;
                buton_submit = false;
            }
        },
        error: function (xhr) {
            // console.log(xhr.status);
        },
    });
    return false;
}

module.exports.initialize = initialize;
