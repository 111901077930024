// window.$ = window.jQuery = require("jquery");
window.$ = window.jQuery = require("../../public/site/js/jquery.min.js");
require("../../public/site/js/bootstrap.min.js");
require("../../public/site/js/jquery.isotope.min.js");
require("../../public/site/js/easing.js");
require("../../public/site/js/owl.carousel");
require("../../public/site/js/jquery.countTo");
// const WOW = require("../../public/site/js/wow.min.js");
// window.wow = new WOW.WOW({ live: false });

// window.wow.init();
// require("../../public/site/js/jquery.magnific-popup.min.js");
// require("../../public/site/js/enquire.min.js");
// require("../../public/site/js/jquery.stellar.min.js");
// require("../../public/site/js/jquery.countdown");
// require("../../public/site/js/countdown-custom");
// require("../../public/site/js/map");
// require("../../public/site/js/designesia");

var jsonValidation = require("./controllers/ajax-validation");

window.initialize = jsonValidation.initialize;

function OpenWindow(query, w, h, scroll) {
    var l = (screen.width - w) / 2;
    var t = (screen.height - h) / 2;

    winprops =
        "resizable=1, height=" +
        h +
        ",width=" +
        w +
        ",top=" +
        t +
        ",left=" +
        l +
        "w";
    if (scroll) winprops += ",scrollbars=1";
    var f = window.open(query, "_blank", winprops);
}

window.OpenWindow = OpenWindow;
